import React from "react";

import { Container } from "./style";

import SectionLogosProps from "./interface";

const SectionLogos = ({ logos, className }: SectionLogosProps) => {
  return (
    <Container className={className}>
      {logos.map((logo) => (
        <div key={logo.image} className="sectionLogos__imgContainer">
          <img
            className="sectionLogos__img"
            src={logo.image}
            alt={logo.altImage}
          />
        </div>
      ))}
    </Container>
  );
};

export default SectionLogos;
