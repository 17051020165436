import React from "react";
import { navigate } from "gatsby";

import BannerBank from "../../assets/images/bg_Banner-back-as-service.jpg";
import BannerBankMobile from "../../assets/images/bg_Banner-back-as-service-mobile.jpg";
import bgLapTopScreen from "../../assets/images/bg_laptop-screen-zipdin.png";
import btCheckGreen from "../../assets/images/bt-check-green.svg";

import Layout from "../../components/Layout";
import Slider from "../../components/Slider";
import TitleSection from "../../components/TitleSection";
import Banner from "../../components/Banner";
import Card from "../../components/Card";
import FullContentWithImage from "../../components/fullContentWithImage";
import NumericList from "../../components/Numeric-list";
import SectionLogos from "../../components/SectionLogos";
import Button from "../../components/Button";

import {
  sliderCars,
  headersSlider,
  cardsBankAsService,
  inovationList,
  otherDifferentials,
  sectionLogosContent,
} from "../../content/pages/bank-as-a-service";

import { Container } from "./styles";

const BankAsServicePage = () => {

  React.useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])
  
  function handleNextPage() {
    navigate("/contato");
  }

  return (
    <Layout>
      <Container>
        <Banner
          backgroundImg={BannerBank}
          classJumbotron="bankAs__jumbotrom info-banner"
          title="Bank as a Service. A sua própria plataforma de crédito"
          text="A Zipdin oferece uma solução digital, de forma completa ou modular, para você montar a sua operação de crédito como quiser e com o mínimo esforço. Você atua como um Banco sem precisar ser um."
        />
        <section className="container bankAs__sectionSolutions">
          <TitleSection
            className="bankAs__titleSolutions"
            title="Nossa solução"
            text=""
          />
          <div className="row justifyment-row">
            {cardsBankAsService.map((card) => (
              <Card
                key={card.title}
                image={card.image}
                altImage={card.altImage}
                title={card.title}
                subtitle={card.subtitle}
                text={card.text}
              />
            ))}
          </div>
        </section>
        <section className="bankAs__inovationSection">
          <TitleSection
            className="bankAs__titleInovation"
            title="Tecnologia e Inovação"
            text=""
          />
          <FullContentWithImage
            image={bgLapTopScreen}
            altImage="Laptop com documentação do Bank as a Service"
            orientation="right"
          >
            <NumericList
              className="bankAs__numericList"
              image={btCheckGreen}
              item={inovationList}
            />
          </FullContentWithImage>
          <TitleSection
            className="bankAs__titleSlider"
            title="Integração e Simplicidade"
            text="Com as nossas APIs você consegue estruturar suas operações de crédito de forma fácil e rápida."
          />
          <Slider
            classContainer="bankAs__slider"
            headers={headersSlider}
            items={sliderCars}
            activeBtnClass="activeBtn"
          />
        </section>
        <section className="container bankAs__otherDifferentials">
          <TitleSection
            className="bankAs__titleOtherDifferentials"
            title="Outros diferenciais"
            text=""
          />

          <div className="row justifyment-row">
            {otherDifferentials.map((card) => (
              <Card
                key={card.title}
                image={card.image}
                altImage={card.altImage}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </section>
        <section className="bankAs__ourExperience">
          <TitleSection
            className="bankAs__titleOurExperience"
            title="Nossa experiência"
            text="Alguns dos clientes que já estruturamos a operação:"
          />
          <SectionLogos
            className="container bankAs__logos"
            logos={sectionLogosContent}
          />
        </section>
        <section className="bankAs__NextPage">
          <Button
            className="bankAs__NextPageBtn"
            onClick={() => handleNextPage()}
            hiddenIcon={false}
          >
            Fale conosco
          </Button>
        </section>
      </Container>
    </Layout>
  );
};

export default BankAsServicePage;
