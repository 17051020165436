import IcServicing from "../../../assets/images/ic_Servicing.svg";
import IcFormalized from "../../../assets/images/ic_Formalized-operations.svg";
import IcGestionsWallet from "../../../assets/images/ic_Gestion-wallet-credit.svg";
import IcEngineCredit from "../../../assets/images/ic_Engine_credit.svg";
import IcCost from "../../../assets/images/ic_mostCost.svg";
import IcCustomization from "../../../assets/images/ic_customization.svg";
import icScalability from "../../../assets/images/ic_scalability.svg";
import icQualification from "../../../assets/images/ic_qualification.svg";
import icDMCard from "../../../assets/images/ic_dm-card.png";
import icFortBrasil from "../../../assets/images/ic_fortBrasil.png";
import icGrupoTirademtes from "../../../assets/images/ic_grupo-tiradentes.png";
import icCalCard from "../../../assets/images/ic_calcard.png";
import icRebel from "../../../assets/images/ic_Rebel.svg";
import icNubank from "../../../assets/images/ic_Nubank.svg";
import icNoverde from "../../../assets/images/ic_Noverde.png";
import icMultiloja from "../../../assets/images/ic_multiloja.png";

export const headersSlider = [
  {
    btnText: "Liquidação",
  },
  {
    btnText: "Carga",
  },
  {
    btnText: "Outros",
  },
];

export const sliderCars = [
  {
    content: [
      {
        id: 0,
        title: "API de Liquidação",
        text:
          "Esta API apresenta todas as liquidações comandadas pela Zipdin SCD assim como o status atualizado de todas elas.",
      },
      {
        id: 1,
        title: "API de Comprovante de Liquidação",
        text:
          "Permite que o cliente do Bank as a Service personalize o comprovante de liquidação que será enviado aos seus clientes.",
      },
      {
        id: 2,
        title: "API de Reapresentação",
        text:
          "Esta API permite a correção dos dados bancários possibilitando a reapresentação das liquidações que sofreram críticas.",
      },
      {
        id: 3,
        title: "Webhook de Liquidação",
        text:
          "Este método avançado de integração de sistemas permite chamar APIs de outros sistemas para informar os resultados das liquidações, possibilitando automação do resultado da liquidação por parte do cliente do Bank as a Service.",
      },
    ],
  },
  {
    content: [
      {
        id: 0,
        title: "API de Carga",
        text:
          "Esta API é responsável por realizar a carga de propostas para o sistema de Bank as a Service, permitindo a realização de cargas de propostas de maneira online uma a uma ou em alto-volume.",
      },
      {
        id: 1,
        title: "Webhook de Carga",
        text:
          "Um método avançado de integração de sistemas onde o Bank as a Service pode chamar APIs de outros sistemas para informar os resultados da carga de propostas, possibilitando automação do resultado da carga por parte do cliente de Bank as a Service.",
      },
    ],
  },
  {
    content: [
      {
        id: 0,
        title: "API de Cancelamento",
        text:
          "Responsável por retirar uma operação do fluxo de bancarização. Dependendo do momento da exclusão, a operação será excluída da base de dados.",
      },
      {
        id: 1,
        title: "API de Cadastro de Estrutura",
        text:
          "Permite ao cliente de Bank as a Service refletir sua estrutura organizacional de fornecimento de crédito, no sistema de bancarização.",
      },
      {
        id: 2,
        title: "API de Consulta ao SCR",
        text:
          "Apesar de não fazer parte do Bank as a Service, a API de consulta ao SCR disponibiliza consultas a centrais de risco como o Banco Central.",
      },
    ],
  },
];

export const headersAccordion = [
  {
    btnText: "Empresa",
  },
  {
    btnText: "Colaborador",
  },
];

export const questionsAccordion = [
  {
    content: [
      {
        id: 0,
        title:
          "Já possuímos um Banco que faz a folha de pagamento e oferece Consignado, isso é um problema?",
        text:
          "Não, o Banco Central não permite nenhum tipo de exclusividade em qualquer produto bancário. A entrada de uma segunda Instituição Financeira na operação de Consignado faz com que a concorrência entre as duas instituições aumente e quem sai ganhando é o seu colaborador com redução das taxas.",
      },
      {
        id: 1,
        title: "A empresa responde como solidária ou avalista da operação?",
        text:
          "Não, a empresa não responde pelos empréstimos de seus colaboradores. Enquanto em folha de pagamento, a obrigação da empresa é descontar e repassar a Instituição Financeira. No caso de afastamento ou desligamento da empresa é necessária a movimentação dentro do Sistema Zipdin e a cobrança é feita diretamente ao Colaborador.",
      },
      {
        id: 2,
        title: "O sistema deve ser instalado no computador da empresa?",
        text:
          "Toda nossa plataforma é via Web. Firmado o convênio, a empresa recebe login e senha para nosso site e lá faz a gestão completa do produto.",
      },
      {
        id: 3,
        title: "Existe algum custo para ser conveniado Zipdin?",
        text:
          "A Zipdin não cobra nada pelo convênio ao empregador. A parceria é feita justamente para que os colaboradores das empresas privadas tenham acesso ao crédito.",
      },
    ],
  },
  {
    content: [
      {
        id: 0,
        title: "Como funciona o empréstimo Consignado?",
        text:
          "O empréstimo consignado funciona como um empréstimo comum, com a diferença de que todo mês, as parcelas da dívida são debitadas diretamente do seu contracheque e repassadas para a Institução Financeira pela empresa empregadora. Dessa forma, enquanto você estiver pagando o empréstimo não poderá evitar esse desconto. “É uma garantia que os bancos e as financeiras têm de que receberão as parcelas em dia, o que traz menos riscos e permite juros menores do que cobrariam em um empréstimo pessoal ou cartão de crédito por exemplo.",
      },
      {
        id: 1,
        title:
          "Estou negativado, mesmo assim consigo ter acesso ao Consignado?",
        text:
          "'Com a Zipdin você vai ter acesso ao Consignado mesmo com negativações. Todas as condições são informadas no momento da simulação do Consignado quando feita análise de Crédito.",
      },
      {
        id: 2,
        title: "Em caso de desligamento como posso pagar meu Consignado?",
        text:
          "É simples! Em sua rescisão a empresa pode descontar até 30% do líquido para amortizar ou quitar seu Consignado (dependendo do saldo devedor). Caso não seja quitado, ainda existe a possibilidade da adesão do Seguro Prestamista que cobre até 5 parcelas no caso de desligamento. Caso ainda tenha saldo devedor, deverá ser pago através de boleto bancário.",
      },
      {
        id: 3,
        title:
          "Utilizei toda minha margem e preciso de um novo empréstimo, o que devo fazer?",
        text:
          "Dependendo do número de parcelas pagas é possível fazer um refinanciamento da proposta inicial com a liberação de mais um valor na conta. Lembramos que antes de solicitar um novo empréstimo é preciso entender a situação financeira como um todo e trabalhar para redução de gastos.",
      },
    ],
  },
];

export const cardsBankAsService = [
  {
    image: IcEngineCredit,
    altImage: "Motor de Crédito",
    title: "Motor de Crédito",
    text:
      "Análise de forma rápida e automática dos seus parâmetros de crédito.",
  },
  {
    image: IcFormalized,
    altImage: "Formalização de Operações de Crédito",
    title: "Formalização",
    subtitle: "de Operações de Crédito",
    text:
      "Com nossas APIs, facilitamos e integramos todo o processo.",
  },
  {
    image: IcServicing,
    altImage: "Servicing",
    title: "Servicing",
    text:
      "Criação de Políticas de Crédito e Cobrança, Análise e Formalização de garantias, verificação e validação de poderes.",
  },
  {
    image: IcGestionsWallet,
    altImage: 'Gestão de Carteira de Crédito',
    title: "Gestão da Carteira de Crédito",
    text:
      "Controle total da sua carteira, realizando as conciliações e interfaces necessárias.",
  },
];

export const inovationList = [
  {
    text: "Sistema 100% dedicado em Bank as a Service",
  },
  {
    text: "Integrações via API com retorno via WebHook",
  },
  {
    text: "Ambiente 100% em nuvem (AWS)",
  },
  {
    text: "Escalabilidade e Alta Disponibilidade",
  },
  {
    text: "Segurança (Protocolo OAuth 2.0 e VPN)",
  },
];

export const otherDifferentials = [
  {
    image: IcCost,
    title: "Melhor custo x Benefício",
    altImage:
      "Custo benefício",
    text:
      "Você economiza tempo com soluções de crédito customizadas e sem burocracia, prontas para serem aplicadas ao seu negócio.",
  },
  {
    image: IcCustomization,
    altImage: "Customização",
    title: "Customização",
    text:
      "Nossas soluções oferecem flexibilidade para você montar sua própria plataforma de crédito.",
  },
  {
    image: icQualification,
    altImage: "Qualificação",
    title: "Equipe Qualificada",
    text:
      "Contamos com uma equipe de profissionais altamente qualificados com vários anos de experiência no setor de crédito.",
  },
  {
    image: icScalability,
    altImage: "Escalabilidade",
    title: "Escalabilidade",
    text:
      "Você tem acesso a condições especiais com os parceiros da Zipdin.",
  },
];

export const sectionLogosContent = [
  {
    image: icNubank,
    altImage: "Nubank",
  },
  {
    image: icRebel,
    altImage: "Rebel",
  },
  {
    image: icNoverde,
    altImage: "Noverde",
  },
  {
    image: icDMCard,
    altImage: "Dm Card",
  },
  {
    image: icGrupoTirademtes,
    altImage: "Grupo Tiradentes",
  },
  {
    image: icFortBrasil,
    altImage: "FortBrasil",
  },
  {
    image: icMultiloja,
    altImage: "Multiloja",
  },
  {
    image: icCalCard,
    altImage: "Calcard",
  },
];
