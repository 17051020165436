import styled from "styled-components";
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.main`
  .bankAs__jumbotrom {

    ${mediaQuery.desktop`
      width: 670px;
      height: 469px;
    `}
  }

  .slider__headersBtn {
    border: 2px solid transparent;
    font-size: 12px;
    margin-right: 10px !important;
    padding: 8px 23px;

    
    ${mediaQuery.desktop`
       font-size: 16px;
       margin-right: 16px !important;
       padding: 15px 43px;
    `}
  }

  .activeBtn {
    color: ${({ theme }) => theme.font.white};
    background: ${({ theme }) => theme.bg.second};
    border: 2px solid ${({ theme }) => theme.font.white};
  }

  .bankAs__sectionSolutions {
    padding-bottom: 0;

    .bankAs__titleSolutions {
      padding-left: 0;
      padding-right: 0;
    }

    ${mediaQuery.desktop`
       padding-bottom: 80px;
    `}
  }

  .bankAs__titleInovation {
    background: ${({ theme }) => theme.bg.second};
    height: 60px;

    h2 {
      color: #fff;
    }
  }

  .bankAs__inovation {
    background: ${({ theme }) => theme.bg.third};

    h2 {
      color: #fff;
    }
  }

  .bankAs__numericList {
    margin-top: -20px;

    .container {
      padding: 0;
      
      ${mediaQuery.desktop`
        padding: inherit;
      `}
    }


    img {
      left: -20px;
      position: absolute !important;

      ${mediaQuery.desktop`
        position: relative !important;
      `}
    }

    li {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.9px;
    }
  }

  .bankAs__titleSlider {
    background: ${({ theme }) => theme.bg.second};
  }

  .bankAs__inovationSection {
    background: ${({ theme }) => theme.bg.second};
    padding-bottom: 80px;

    ${mediaQuery.desktop`
       padding-bottom: 300px;
    `}

    h2 {
      color: ${({ theme }) => theme.font.white};
    }

    p {
      color: rgba(255, 255, 255, 0.8);
    }

    .bankAs__slider {
      p {
        color: ${({ theme }) => theme.font.primary};
      }
    }
  }

  .bankAs__otherDifferentials {
    background: ${({ theme }) => theme.bg.primary};
    position: relative;
    top: 0;

    ${mediaQuery.desktop`
       top: -165px;
    `}

    .bankAs__titleOtherDifferentials {
      background: ${({ theme }) => theme.bg.primary};
    }
  }

  .bankAs__ourExperience {
    background: ${({ theme }) => theme.bg.fifth};
    p {
      margin-bottom: 50px;
    }

    .bankAs__logos {
      max-width: 850px;
    }

    .bankAs__titleOurExperience {
      background: ${({ theme }) => theme.bg.fifth};

      p {
        font-size: 18px;
        letter-spacing: 0.9px;
      }
    }
  }

  .bankAs__NextPage {
    justify-content: center;
    padding: 80px 0;
    margin: 0 auto;
    width: 90%;

    ${mediaQuery.desktop`
       display: flex;
       padding: 150px 0;
    `}

    button {
      font-weight: 600;
      text-transform: uppercase;
      min-height: 53px;
      width: 100%;

      ${mediaQuery.desktop`
       display: flex;
       width: auto;
    `}
    }
  }
`;
