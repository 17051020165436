import styled from "styled-components";
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: 850px;
  justify-content: center;
  padding-bottom: 0x;

  ${mediaQuery.desktop`
    padding-bottom: 75px;
  `}

  .sectionLogos__imgContainer {
    width: 50%;

    ${mediaQuery.desktop`
      width: 25%;
    `}

    img {
      max-width: 100%;
    }

    margin-bottom: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
